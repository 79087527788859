.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chakra-checkbox__control{
  color: #C7CAD1 !important;
}

.chakra-avatar__excess{
  margin-left: 1px !important;
}

:root{
  --tag-color: #000;
}

.react-datetime-picker__wrapper {
  border: 1px solid #E3E4E8;
  border-radius: 8px;
  height: 40px;
  width: 100%;
}

.react-datetime-picker{
  width: 100%;
}

.fc-toolbar-title{
  font-size: 16px !important;
  font-weight: 500 !important;
}

/* .chakra-select__icon-wrapper{
  right: -15px !important;
  top: 0 !important;
} */

span[role=listitem]{
  color: #000 !important;
}

@media (max-width : 400px) {
  .fc .fc-toolbar {
    display: block !important;
  }
  .fc .fc-toolbar:first-child {
    margin-top: 40px;
  }
}