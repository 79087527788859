body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.chartjs-tooltip {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input.timetime[type=time]::-webkit-datetime-edit-ampm-field {
  display: none;
  }
.rs-picker-date-menu.rs-picker-menu {
  z-index: 9999999999 !important;
  position: absolute;
  /* top: -40px; */

}
.rs-picker-toggle {
  display: none !important;
}

.paginator {
  display: flex;
  list-style: none;
  gap: 4px;
  align-items: center;
}
.pageClassName {
  width: 30px;
  height: 30px;
  min-width: 30px;
  background-color: rgb(210, 210, 210);
  border-radius: 100%;
  display: flex;
  color: rgb(20, 20, 20);
  justify-content: center;
  align-items: center;
}
.activeClassName {
  background-color: #7353ba;
  color: white;
}
.pageLinkClass {
  font-weight: bold;
  font-size: 12px;
}
.rdp-day_selected {
  background-color: #7353ba !important;
}